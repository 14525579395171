"use client"; // Ensure this is a Client Component

import { Inter } from "next/font/google";
import "./globals.css";
import Nav from "@/components/ui/nav/Nav";
import Foot from "@/components/ui/foot/Foot";
import { GoogleAnalytics, GoogleTagManager } from '@next/third-parties/google';
import { ReactNode, useState, useEffect } from 'react';
import CookieBanner from "@/components/privacy/CookieBanner";
import Loader from "@/components/ui/loader/loader"; // Ensure the path is correct
import ServerOnly from "@/components/sanity/Ssrvonly";
import SearchBar from "@/components/sanity/SearchBar";

import { SessionProvider } from 'next-auth/react';
import { Session } from 'next-auth'
const inter = Inter({ subsets: ["latin"] });

interface RootLayoutProps {
  children: ReactNode;
  session: Session | null;

}

export default function RootLayout({ children,session}: RootLayoutProps) {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const handleStart = () => setLoading(true);
    const handleComplete = () => setLoading(false);

    // Add event listeners for route changes
    window.addEventListener('routeChangeStart', handleStart);
    window.addEventListener('routeChangeComplete', handleComplete);
    window.addEventListener('routeChangeError', handleComplete);

    return () => {
      // Remove event listeners
      window.removeEventListener('routeChangeStart', handleStart);
      window.removeEventListener('routeChangeComplete', handleComplete);
      window.removeEventListener('routeChangeError', handleComplete);
    };
  }, []);


  return (
    <>
     <SessionProvider session={session}>
      <html lang="en">
        <GoogleTagManager gtmId="GTM-P6PQXG6N" />
        <GoogleAnalytics gaId="G-74YXFGPXYS" />
        <meta name="google-adsense-account" content="ca-pub-5974769252854372" />
        <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-5974769252854372"
          crossOrigin="anonymous"></script>
        <meta name="msvalidate.01" content="38097EA171297AC0BD100E794AA3593B" />
        <meta name="robots" content="index,follow" />
        <meta name="theme-color" content="#000000" />
        <link rel="manifest" href="/manifest.json" />
        <link rel="apple-touch-icon" href="/icons/appassets/32.png" />
        <link rel="apple-touch-icon" sizes="144x144" href="/icons/appassets/144.png" />
        <link rel="apple-touch-icon" sizes="152x152" href="/icons/appassets/152.png" />
        <link rel="apple-touch-icon" sizes="180x180" href="/icons/appassets/180.png" />
        <link rel="apple-touch-icon" sizes="167x167" href="/icons/appassets/167.png" />
        <link rel="icon" type="image/x-icon" href="./favicon.ico" />
       
          <body className={inter.className}>
            <Nav />
            {loading && <Loader />}
            <SearchBar />
            {children}
            <CookieBanner />
            <Foot />
          </body>
        {/* </SessionProvider> */}
      </html>
      </SessionProvider>
    </>
  );
}

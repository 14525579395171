import { SanityClient, createClient } from 'next-sanity'

import { 
  apiVersion, 
  dataset, projectId, useCdn, studioUrl } from '../env'

import {
  indexQuery,
  type Post,
  postAndMoreStoriesQuery,
  postBySlugQuery,
  postSlugsQuery,
  type Settings,
  settingsQuery,
} from '@/sanity/lib/queries'


export const client = createClient({
  apiVersion,
  dataset,
  projectId,
  useCdn,
  // These settings will be overridden in 
  // ./sanity/lib/store.ts when draftMode is enabled
  perspective: "published",
  stega: {
    enabled: false,
    studioUrl: "/studio",
  },
});


export function getClient(preview?: { token: string }): SanityClient {
  const client = createClient({
    projectId,
    dataset,
    apiVersion,
    useCdn,
    perspective: 'published',
    stega: {
      enabled: preview?.token ? true : false,
      studioUrl: "/studio",
    },
  })
  if (preview) {
    if (!preview.token) {
      throw new Error('You must provide a token to preview drafts')
    }
    return client.withConfig({
      token: preview.token,
      useCdn: false,
      ignoreBrowserTokenWarning: true,
      perspective: 'previewDrafts',
    })
  }
  return client
}

export const getSanityImageConfig = () => getClient()

export async function getSettings(client: SanityClient): Promise<Settings> {
  return (await client.fetch(settingsQuery)) || {}
}

export async function getAllPosts(client: SanityClient): Promise<Post[]> {
  return (await client.fetch(indexQuery)) || []
}

// export async function getAllPostsSlugs(): Promise<Pick<Post, 'slug'>[]> {
//   const client = getClient()
//   const slugs = (await client.fetch<string[]>(postSlugsQuery)) || []
//   return slugs.map((slug) => ({ slug }))
// }

export async function getPostBySlug(
  client: SanityClient,
  slug: string,
): Promise<Post> {
  return (await client.fetch(postBySlugQuery, { slug })) || ({} as any)
}

export async function getPostAndMoreStories(
  client: SanityClient,
  slug: string,
): Promise<{ post: Post; morePosts: Post[] }> {
  return await client.fetch(postAndMoreStoriesQuery, { slug })
}

interface Metadata {
  title: string;
  description: string;
}

// export async function fetchMetadata(): Promise<Metadata> {
//   const query = `*[_type == "settings"][0]{title, description}`;
//   const metadata: Metadata | null = await client.fetch(query);
//   console.log('Fetched Metadata in client:', metadata); // Debug log
//   // Check if metadata is null, return default values if so
//   if (!metadata) {
//     console.warn('Metadata is null or undefined');
//     return { title: 'Default Title', description: 'Default description' };
//   }

//   return metadata;
// }

export async function fetchMetadata(preview?: { token: string }): Promise<Metadata> {
  const client = getClient(preview);
  const query = `*[_type == "settings"][0]{title, description}`;
  const metadata: Metadata | null = await client.fetch(query);

  console.log('Fetched Metadata in client:', metadata); // Debug log

  if (!metadata) {
    return { title: 'Adobe Foundation', description: 'Default description in client' };
  }

  return metadata;
}
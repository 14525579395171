// components/NavBar.tsx
"use client"
import Link from 'next/link';
import Image from 'next/image';
import React, { useState } from 'react';

const Nav: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isMobileDropdownOpen, setIsMobileDropdownOpen] = useState(false);
  const [isPrivacyOpen, setIsPrivacyOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const togglePrivacy = () => {
    setIsPrivacyOpen((prev) => !prev); // Toggle isPrivacyOpen state
    if (isMobileDropdownOpen) {
      setIsMobileDropdownOpen(false); // Close mobile dropdown if open
    }
  };

  const toggleMobileDropdown = () => {
    setIsMobileDropdownOpen((prev) => !prev); // Toggle isMobileDropdownOpen state
    if (isOpen) {
      setIsOpen(false); // Close desktop dropdown if open
    }
  };
  // setIsMobileDropdownOpen(!isMobileDropdownOpen);
  // };

  const closeMenu = () => {
    setIsOpen(false);
    setIsMobileDropdownOpen(false);
  };
  return (
    <nav className="bg-gray-800 p-4 ">
      <div className="container mx-auto flex justify-between items-center">
        <Link href="/" aria-label="Home Page" className="flex items-center text-white">
          <Image
            src="/af.svg"
            alt="Logo"
            width={128}
            height={128}
            className=" m-1 rounded-lg"
            sizes="100vw"
            style={{ width: '70%', height: '40%' }}
          />
          <span className="font-semibold text-xl"></span>
        </Link>
        <div className="hidden md:flex space-x-4">
          <Link href="/blogs" aria-label="Blogs Page" className="text-white hover:text-gray-300">Blogs</Link>
          <Link href="/contact" aria-label="Constact Us Page" className="text-white hover:text-gray-300">Contact Us</Link>
          <Link href="/about" aria-label="About Us Page" className="text-white hover:text-gray-300">About Us</Link>
          {/* Dropdown menu */}
          <div className="relative">
            <button onClick={toggleMenu} className="text-white focus:outline-none">
              Policies
              <svg
                className="h-5 w-5 inline-block ml-1 -mt-1"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                {isOpen ? (
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 15l7-7 7 7" />
                ) : (
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
                )}
              </svg>
            </button>
            {isOpen && (
              <div className="absolute right-0 mt-2 bg-gray-800 text-white shadow-lg py-1 rounded-lg w-40">
                <Link href="/policies" aria-label="All Policies Page" className="block px-4 py-2 text-sm" onClick={closeMenu}>
                    All Policies
                  </Link>
                  <Link href="/policies/privacy" aria-label="Privacy policy Page" className="block px-4 py-2 text-sm" onClick={closeMenu}>
                    Privacy Policy
                  </Link>
                  <Link href="/policies/terms" aria-label="Terms and Conditions Page" className="block px-4 py-2 text-sm" onClick={closeMenu}>
                    Terms and Conditions
                  </Link>
                  <Link href="/policies/cookies" aria-label="Cookie Policy Page" className="block px-4 py-2 text-sm" onClick={closeMenu}>
                    Cookies
                  </Link>
                  <Link href="/policies/userrights" aria-label="User Rights Page" className="block px-4 py-2 text-sm" onClick={closeMenu}>
                    User Rights Policies
                  </Link>
              </div>
            )}
          </div>
        </div>


        <div className="md:hidden flex items-center">
          <button onClick={toggleMenu} className="text-white focus:outline-none" aria-label="Mobile Menu">
            <svg className="h-6 w-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              {isOpen ? (
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
              ) : (
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16m-7 6h7" />
              )}
            </svg>
          </button>
        </div>
      </div>
      {
        isOpen && (
          <div className="md:hidden flex flex-col items-center space-y-4 mt-4">
            <><Link href="/blogs" aria-label="All Blogs Page" className="text-white hover:text-gray-300" onClick={() => setIsOpen(false)}>Blogs</Link>
              <Link href="/contact" aria-label="Contact Us Page" className="text-white hover:text-gray-300" onClick={() => setIsOpen(false)}>Contact Us</Link>
              <Link href="/about" aria-label="About Us Page" className="text-white hover:text-gray-300" onClick={() => setIsOpen(false)}>About Us</Link></>
            {/* Dropdown links */}
            {/* <Link href="/policies" className="block px-4 py-2 text-sm" onClick={closeMenu}>
              All Policies
            </Link>
            <Link href="/policies/privacy" className="block px-4 py-2 text-sm" onClick={closeMenu}>
              Privacy Policy
            </Link>
            <Link href="/policies/terms" className="block px-4 py-2 text-sm" onClick={closeMenu}>
              Terms and Conditions
            </Link>
            <Link href="/policies/cookies" className="block px-4 py-2 text-sm" onClick={closeMenu}>
              Cookies
            </Link>
            <Link href="/policies/userrights" className="block px-4 py-2 text-sm" onClick={closeMenu}>
              User Rights Policies
            </Link> */}

            {/* Mobile dropdown links */}
            <div className="relative">
              <button onClick={togglePrivacy} className="text-white focus:outline-none">
                Policies
                <svg
                  className="h-5 w-5 inline-block ml-1 -mt-1"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  {isPrivacyOpen ? (
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 15l7-7 7 7" />
                  ) : (
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
                  )}
                </svg>
              </button>
              {isPrivacyOpen && (
                // <div className="md:hidden mt-2 bg-gray-800 text-white shadow-lg py-1 rounded-lg w-full px-4">
                <div className="right-0 mt-2 bg-gray-800 text-white shadow-lg py-1 rounded-lg w-46">
                  <Link href="/policies" aria-label="All Policies Page" className="block px-4 py-2 text-sm" onClick={closeMenu}>
                    All Policies
                  </Link>
                  <Link href="/policies/privacy" aria-label="Privacy policy Page" className="block px-4 py-2 text-sm" onClick={closeMenu}>
                    Privacy Policy
                  </Link>
                  <Link href="/policies/terms" aria-label="Terms & Conditions Page" className="block px-4 py-2 text-sm" onClick={closeMenu}>
                    Terms and Conditions
                  </Link>
                  <Link href="/policies/cookies" aria-label="Cookie Policy Page" className="block px-4 py-2 text-sm" onClick={closeMenu}>
                    Cookies
                  </Link>
                  <Link href="/policies/userrights" aria-label="User Rights Page" className="block px-4 py-2 text-sm" onClick={closeMenu}>
                    User Rights Policies
                  </Link>
                </div>
                // </div>
              )}
            </div>
          </div>
          // </div>

        )
      }

      {/* Mobile menu */}
      {/* <div className="md:hidden flex items-center ">
  
          <button onClick={toggleMobileDropdown} className=" text-white focus:outline-none">
          <span className="font-semibold text-xl"></span>
            <svg
              className="h-6 w-6 ml-1 "
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              {isMobileDropdownOpen ? (
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
              ) : (
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16m-7 6h7" />
              )}
            </svg> */}
      {/* </button> */}
      {/* 
      <div className="md:hidden flex items-center">
        <button onClick={toggleMobileDropdown} className="text-white focus:outline-none">
          <svg className="h-6 w-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            {isOpen ? (
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
            ) : (
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16m-7 6h7" />
            )}
          </svg>
        </button>
        {isMobileDropdownOpen && (
          // <div className="absolute mt-2 bg-gray-800 text-white shadow-lg py-1 rounded-lg w-40">
          <div className=" md:hidden flex flex-col items-center space-y-4 mt-4">
            <Link href="/blogs" className=" text-white hover:text-gray-300" onClick={closeMenu}>
              Blogs
            </Link>
            <Link href="/contact" className="block px-4 py-2 text-sm" onClick={closeMenu}>
              Contact Us
            </Link>
            <Link href="/about" className="block px-4 py-2 text-sm" onClick={closeMenu}>
              About Us
            </Link>
            {/* Mobile dropdown links */}
      {/* <div className="relative">
              <button onClick={toggleMenu} className="text-white focus:outline-none">
                Privacy
                <svg
                  className="h-5 w-5 inline-block ml-1 -mt-1"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  {isOpen ? (
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 15l7-7 7 7" />
                  ) : (
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
                  )}
                </svg>
              </button>
              {isOpen && (
                <div className="absolute right-0 mt-2 bg-gray-800 text-white shadow-lg py-1 rounded-lg w-40">
                  <Link href="/policies-policy" className="block px-4 py-2 text-sm" onClick={closeMenu}>
                    Privacy Policy
                  </Link>
                  <Link href="/terms-and-conditions" className="block px-4 py-2 text-sm" onClick={closeMenu}>
                    Terms and Conditions
                  </Link>
                  <Link href="/cookies" className="block px-4 py-2 text-sm" onClick={closeMenu}>
                    Cookies
                  </Link>
                </div>
              )}
            </div>
          </div>
        )} */}
      {/* </div> */}
      {/* </div>  */}
    </nav >
  );
};

export default Nav;

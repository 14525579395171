import React, { useState, useEffect, useRef } from 'react';
import Image from 'next/image'; // Import the Next.js Image component
import urlForImage from '@/sanity/lib/image'; // Adjust the path as needed
import { fetchBlogs } from '@/sanity/lib/fetch1'; 

interface Post {
  _id: string;
  title: string;
  description: string;
  mainImage: {
    asset: {
      _id: string;
      _type: string;
      _ref: string;
    };
  };
  slug: {
    current: string;
  };
  keywords?: string;
}

const SearchBar: React.FC = () => {
  const [query, setQuery] = useState('');
  const [posts, setPosts] = useState<Post[]>([]);
  const [filteredPosts, setFilteredPosts] = useState<Post[]>([]);
  const [isOpen, setIsOpen] = useState(false);

  // Refs to handle clicks outside
  const searchBarRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const postsData = await fetchBlogs();
        setPosts(postsData);
        setFilteredPosts(postsData);
      } catch (error) {
        console.error('Error fetching posts:', error);
      }
    };

    fetchPosts();
  }, []);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const searchQuery = e.target.value;
    setQuery(searchQuery);
    setIsOpen(!!searchQuery); // Show overlay when there's input

    if (!searchQuery) {
      setFilteredPosts(posts);
    } else {
      const lowercasedQuery = searchQuery.toLowerCase();
      const filtered = posts.filter(post =>
        post.title?.toLowerCase().includes(lowercasedQuery) ||
        post.description?.toLowerCase().includes(lowercasedQuery) ||
        post.keywords?.toLowerCase().includes(lowercasedQuery)
      );
      setFilteredPosts(filtered);
    }
  };

  const handleOverlayClick = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (searchBarRef.current && !searchBarRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div ref={searchBarRef} className="relative">
      <div className="flex justify-center my-4">
        <input
          type="text"
          value={query}
          onChange={handleInputChange}
          placeholder="Search for blogs..."
          className="px-4 py-2 w-full max-w-md rounded-md border border-gray-300 focus:outline-none focus:border-blue-500"
        />
      </div>

      {isOpen && (
        <div className="absolute top-full left-0 w-full mt-2 bg-white shadow-lg rounded-lg border border-gray-300 z-50 max-h-[60vh] overflow-y-auto">
          <ul className="list-none p-0 m-0">
            {filteredPosts.length > 0 ? (
              filteredPosts.map((post) => (
                <li key={post._id} className="p-4 border-b border-gray-200 hover:bg-gray-100">
                  <a href={`/posts/${post.slug.current}`} className="flex items-center space-x-4">
                    {post.mainImage?.asset && (
                      <div className="relative h-16 w-16 flex-shrink-0">
                        <Image
                          src={urlForImage(post.mainImage.asset)}
                          alt={post.title}
                          layout="fill"
                          objectFit="cover"
                          className="rounded-md"
                        />
                      </div>
                    )}
                    <div>
                      <h2 className="text-lg font-semibold">{post.title}</h2>
                      <p className="text-sm text-gray-600">{post.description}</p>
                    </div>
                  </a>
                </li>
              ))
            ) : (
              <li className="p-4 text-center text-gray-600">No results found</li>
            )}
          </ul>
        </div>
      )}
    </div>
  );
};

export default SearchBar;

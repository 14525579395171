import Link from 'next/link';
import { FaHome, FaBlog, FaEnvelope, FaInfoCircle, FaShieldAlt, FaUserShield, FaCookieBite, FaGavel } from 'react-icons/fa';

const Foot: React.FC = () => {
  return (
    <>
      <footer className="bg-gray-800 p-4 inset-x-0 bottom-0">
        <div className="space-y-2">
          <div className="flex flex-col md:flex-row justify-center space-y-4 md:space-y-0 md:space-x-4">
            <div className="bg-gray-700 p-4 rounded-md shadow-md">
              <div className="flex text-center flex-col space-y-2">
                <Link href="/" aria-label="Home Page" className="text-white hover:text-gray-300 flex items-center space-x-2">
                  <FaHome />
                  <span>Home</span>
                </Link>
                <Link href="/blogs" aria-label="All Blogs Page" className="text-white hover:text-gray-300 flex items-center space-x-2">
                  <FaBlog />
                  <span>Blogs</span>
                </Link>
                <Link href="/contact" aria-label="Contact Us Page" className="text-white hover:text-gray-300 flex items-center space-x-2">
                  <FaEnvelope />
                  <span>Contact Us</span>
                </Link>
                <Link href="/about" aria-label="About Us Page" className="text-white hover:text-gray-300 flex items-center space-x-2">
                  <FaInfoCircle />
                  <span>About Us</span>
                </Link>
              </div>
            </div>
            <div className="bg-gray-700 p-4 rounded-md shadow-md">
              <div className="flex text-center flex-col space-y-2">
                <Link href="/policies" aria-label="Adobe Foundation All Policies Page" className="text-white hover:text-gray-300 flex items-center space-x-2">
                  <FaShieldAlt />
                  <span>Our Policies</span>
                </Link>
                <Link href="/policies/privacy" aria-label="Privacy Policy Page" className="text-white hover:text-gray-300 flex items-center space-x-2">
                  <FaUserShield />
                  <span>Privacy Policies</span>
                </Link>
                <Link href="/policies/terms" aria-label="Terms & Condition Page" className="text-white hover:text-gray-300 flex items-center space-x-2">
                  <FaGavel />
                  <span>Terms & Conditions</span>
                </Link>
                <Link href="/policies/cookies" aria-label="Cookie Policy Page" className="text-white hover:text-gray-300 flex items-center space-x-2">
                  <FaCookieBite />
                  <span>Cookie Policies</span>
                </Link>
                <Link href="/policies/userrights" aria-label="User Rights Page" className="text-white hover:text-gray-300 flex items-center space-x-2">
                  <FaShieldAlt />
                  <span>User Rights Policies</span>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="text-white flex text-center justify-center space-x-4 space-y-4 mt-4">
          &copy; {new Date().getFullYear()} Adobe Foundation. All rights are reserved.
        </div>
      </footer>
    </>
  );
};

export default Foot;

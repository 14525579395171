"use client";
import React from 'react';

const Loader = () => {
  return (
    <div className="flex justify-center items-center min-h-screen">
      <div className="relative w-16 h-16">
        <div className="absolute top-0 left-0 w-full h-full bg-red-600 animate-spin rounded-full"></div>
        <div className="absolute top-2 left-2 w-12 h-12 bg-white rounded-full"></div>
        <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 100 100"
            fill="currentColor"
            className="w-8 h-8 text-red-600"
          >
            <path d="M50 0L100 100H0L50 0zM44 60L31 75H44V60zM56 60L69 75H56V60z" />
          </svg>
        </div>
      </div>
    </div>
  );
};

export default Loader;
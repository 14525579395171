import { SanityClient } from "next-sanity";
import { client } from "./client";
import { CATEGORIES_QUERY, Category } from "./queries";

export async function sanityFetch<QueryResponse>({
  query,
  params = {},
  perspective = "published",
  stega = false,
}: {
  query: string;
  params?: any;
  perspective?: "published" | "previewDrafts";
  stega?: boolean;
}) {
  const options: any = {
    stega,
    perspective,
    useCdn: perspective === "published",
    next: { revalidate: perspective === "published" ? 60 : 0 },
  };

  if (perspective === "previewDrafts") {
    options.token = process.env.SANITY_API_TOKEN; // Make sure this environment variable is set
    options.useCdn = false;
  }

  return client.fetch<QueryResponse>(query, params, options);
}

export async function fetchBlogs() {
    const query = '*[_type == "post"]{_id, title, description, mainImage, slug,"imageUrl": mainImage.asset->url}';
    const blogs = await client.fetch(query);
    return blogs;
}

export async function getCategories(client: SanityClient): Promise<Category[]> {
  const query = CATEGORIES_QUERY;
  return await client.fetch<Category[]>(query);
}
// // ./sanity/lib/queries.ts

// import { groq } from "next-sanity";
// import {client} from "./client"
// import { SanityClient } from 'next-sanity';
// import { NextApiRequest, NextApiResponse } from "next";

// export const POSTS_QUERY = groq`*[_type == "post" && defined(slug)]`;
// export const POST_QUERY = groq`*[_type == "post" && slug.current == $slug][0]`;

// const postFields = groq`
//   _id,
//   title,
//   date,
//   keywords,
//   _updatedAt,
//   excerpt,
//   description,
//   mainImage,
//   "slug": slug.current,
//   "author": author->{name, picture},
// `

// export const settingsQuery = groq`*[_type == "settings"][0]`

// export const indexQuery = groq`
// *[_type == "post"] | order(date desc, _updatedAt desc) {
//   ${postFields}
// }`

// export const postAndMoreStoriesQuery = groq`
// {
//   "post": *[_type == "post" && slug.current == $slug] | order(_updatedAt desc) [0] {
//     content,
//     ${postFields}
//   },
//   "morePosts": *[_type == "post" && slug.current != $slug] | order(date desc, _updatedAt desc) [0...2] {
//     content,
//     ${postFields}
//   }
// }`

// export const postSlugsQuery = groq`
// *[_type == "post" && defined(slug.current)][].slug.current
// `

// export const postBySlugQuery = groq`
// *[_type == "post" && slug.current == $slug][0] {
//   ${postFields}
// }
// `;
// export const CATEGORIES_QUERY = groq`*[_type == "category"]{
//   _id,
//   title,
//   "slug": slug.current,
//   description
// }`;

// export interface Category {
//   _id: string;
//   title: string;
//   slug: {
//     current: string;
//   };
//   description: string;
// }

// export interface Author {
//   name?: string
//   picture?: any
// }

// export interface Post {
  
//   _id: string
//   title?: string
//   mainImage?: any
//   date?: string
//   keywords?: string
//   _updatedAt?: string
//   excerpt?: string
//   description?: string
//   author?: Author
//   slug?: string
//   content?: any
//   categories?:string
// }

// export interface Settings {
//   title?: string
//   description?: any[]
//   ogImage?: {
//     title?: string
//   }
// }

// export interface Posta {
//     slug?: { current: string };
//     _updatedAt?: string;
//     _id: string;
//     title: string;
//     excerpt: string;
//     mainImage: {
//         asset: {
//           url: string;
//         };
//         alt: string;
//       };
//   }
// export async function getAllPostssmp(client: SanityClient): Promise<Posta[]> {
//     const query = `*[_type == "post"]{slug, _updatedAt}`;
//     return await client.fetch(query);
//   }

//   export async function MpProps(client: SanityClient): Promise<Posta[]> {
//     try {
//       const query = `*[_type == "post"] | order(publishedAt desc)[0...3]{
//         _id,
//         title,
//         excerpt,
//         slug,
//         mainImage {
//           asset -> {
//             _id,
//             url
//           },
//           alt
//         }
//       }`;
  
//       const latestPosts: Posta[] = await client.fetch(query);
//       return latestPosts;
//     } catch (error) {
//       console.error('Error fetching latest posts:', error);
//       return []; // Return empty array if there's an error
//     }
//   }

//   export async function getAllPost(client: SanityClient): Promise<Post[]> {
//     const query = `*[_type == "post"]{slug, _updatedAt}`;
//     return await client.fetch(query);
//   }

//   export async function MpPostPrp(client: SanityClient): Promise<Post[]> {
//     try {
//       const query = `*[_type == "post"] | order(publishedAt desc)[0...3]{
//         _id,
//         title,
//         excerpt,
//         slug,
//         mainImage {
//           asset -> {
//             _id,
//             url
//           },
//           alt
//         }
//       }`;
  
//       const latestPosts: Post[] = await client.fetch(query);
//       return latestPosts;
//     } catch (error) {
//       console.error('Error fetching latest posts:', error);
//       return []; // Return empty array if there's an error
//     }
//   }

//   const query = groq`*[_type == "settings"][0]{
//     title,
//     description
//   }`;

//   export default async function handler(req: NextApiRequest, res: NextApiResponse) {
//     const metadata = await client.fetch(query);
//     res.status(200).json(metadata);
//   }

// ./sanity/lib/queries.ts

import { groq } from "next-sanity";
import { client } from "./client";
import { SanityClient } from 'next-sanity';
import { NextApiRequest, NextApiResponse } from "next";

// Queries
export const POSTS_QUERY = groq`*[_type == "post" && defined(slug)]`;
export const POST_QUERY = groq`*[_type == "post" && slug.current == $slug][0]`;

const postFields = groq`
  _id,
  title,
  date,
  keywords,
  _updatedAt,
  excerpt,
  description,
  mainImage,
  "slug": slug.current,
  "author": author->{name, picture},
`;

//  const settingsQuery = groq`*[_type == "settings"][0]`;

export const indexQuery = groq`
*[_type == "post"] | order(date desc, _updatedAt desc) {
  ${postFields}
}`;

export const postAndMoreStoriesQuery = groq`
{
  "post": *[_type == "post" && slug.current == $slug] | order(_updatedAt desc) [0] {
    content,
    ${postFields}
  },
  "morePosts": *[_type == "post" && slug.current != $slug] | order(date desc, _updatedAt desc) [0...2] {
    content,
    ${postFields}
  }
}`;

export const postSlugsQuery = groq`
*[_type == "post" && defined(slug.current)][].slug.current
`;

export const postBySlugQuery = groq`
*[_type == "post" && slug.current == $slug][0] {
  ${postFields}
}`;

export const CATEGORIES_QUERY = groq`
*[_type == "category"]{
  _id,
  title,
  "slug": slug.current,
  description
}`;

// Interfaces
export interface Category {
  _id: string;
  title: string;
  slug: {
    current: string;
  };
  description: string;
  CatImage: any;
}

export interface Author {
  _id: string | undefined;
  name?: string;
  picture?: any;
}

export interface User {
  _type: 'user';
  _id: string;
  username: string;
  email: string;
  avatar?: {
    asset: {
      _ref: string;
      _type: 'reference';
    };
    _type: 'image';
    alt?: string;
  };
  role: 'registeredUser' | 'admin';
  isActive?: boolean;
}

export interface Post {
  userId: string;
  publishedAt: any;
  body: string;
  _createdAt: any;
  published: boolean;
  _id: string;
  title?: string;
  mainImage?: any;
  date?: string;
  keywords?: string;
  _updatedAt?: string;
  excerpt?: string;
  description?: string;
  author?: Author;
  slug?:{ current?: string };  
  // string;
  content?: any;
  categories?: string[];
}

export interface Settings {
  title?: string;
  description?: any[];
  ogImage?: {
    title?: string;
  };
}

export interface Posta {
  slug?: { current: string };
  _updatedAt?: string;
  _id: string;
  title: string;
  excerpt: string;
  mainImage: {
    asset: {
      url: string;
    };
    alt: string;
  };
}

// Functions
export async function getAllPostssmp(client: SanityClient): Promise<Posta[]> {
  const query = `*[_type == "post"]{slug, _updatedAt}`;
  return await client.fetch(query);
}

export async function MpProps(client: SanityClient): Promise<Posta[]> {
  try {
    const query = `*[_type == "post"] | order(publishedAt desc)[0...3]{
      _id,
      title,
      excerpt,
      slug,
      mainImage {
        asset -> {
          _id,
          url
        },
        alt
      }
    }`;

    const latestPosts: Posta[] = await client.fetch(query);
    return latestPosts;
  } catch (error) {
    console.error('Error fetching latest posts:', error);
    return []; // Return empty array if there's an error
  }
}

export async function getAllPost(client: SanityClient): Promise<Post[]> {
  const query = `*[_type == "post"]{slug, _updatedAt}`;
  return await client.fetch(query);
}

export async function MpPostPrp(client: SanityClient): Promise<Post[]> {
  try {
    const query = `*[_type == "post"] | order(publishedAt desc)[0...3]{
      _id,
      title,
      excerpt,
      slug,
      mainImage {
        asset -> {
          _id,
          url
        },
        alt
      }
    }`;

    const latestPosts: Post[] = await client.fetch(query);
    return latestPosts;
  } catch (error) {
    console.error('Error fetching latest posts:', error);
    return []; // Return empty array if there's an error
  }
}

// API Handler
export const settingsQuery = groq`*[_type == "settings"][0]{
  title,
  description
}`;

export async function handler(req: NextApiRequest, res: NextApiResponse) {
  try {
    const metadata = await client.fetch(settingsQuery);
    res.status(200).json(metadata);
  } catch (error) {
    console.error('Error fetching settings:', error);
    res.status(500).json({ error: 'Failed to fetch settings' });
  }
}

const allindexQuery = `
  *[_type == "post" && defined(publishedAt)] {
    _id,
    title,
    slug,
    keywords,
    excerpt,
    description,
    mainImage,
    publishedAt,
    body,
    author->{
      _id,
      name,
      slug,
      authImage,
      bio
    },
    categories[]->{
      _id,
      title
    },
    createdAt,
    updatedAt
  } | order(publishedAt desc)
`;

export async function getAllPrismaPosts(client: SanityClient): Promise<Post[]> {
  return (await client.fetch(allindexQuery)) || [];
}